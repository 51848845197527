<template>
    <div id="chart">
        <apexchart type="line" :height="$vuetify.breakpoint.lgAndUp ? '265' : '360'" ref="chart" :options="chartOptions" :series="series"></apexchart>
    </div>    
</template>

<script>
export default {
  props: {
    ano: Number
  },
  data() {
      return {
        obj: {
          name: '',
          data: []
        },
        obj1: {
          name: '',
          data: []
        },
        obj2: {
          name: '',
          data: []
        },
        obj3: {
          name: '',
          data: []
        },
        obj4: {
          name: '',
          data: []
        },
        value: [],
        series: [],
        chartOptions: {
          chart: {
            height: 600,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'straight'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: ['2018', '2019', '2020', '2021'],
          }
        },   
      }
    },
    methods: {
      getByYears(){
        this.axios.get('turma/quantitativomatriculas')
        .then(res => {

          for(let i = 0; i < 16; i++){
            
            // 0 ~ 3
            if(i === 3){
              this.obj.name = 'Creche'
              // creche do ano atual - 4 anos
              this.obj.data[0] = res.data[0].Quantidade
              // pre escola do ano atual - 4 anos
              this.obj.data[1] = res.data[4].Quantidade
              // anos iniciais do ano atual - 4 anos
              this.obj.data[2] = res.data[8].Quantidade
              // anos finais do ano atual - 4 anos
              this.obj.data[3] = res.data[12].Quantidade
            }

            // 4 ~ 7
            if(i > 3 && i < 8){
              this.obj1.name = 'Pre Escola'
              // creche do ano atual - 3 anos
              this.obj1.data[0] = res.data[1].Quantidade
              // pre escola do ano atual - 3 anos
              this.obj1.data[1] = res.data[5].Quantidade
              // anos iniciais do ano atual - 3 anos
              this.obj1.data[2] = res.data[9].Quantidade
              // anos finais do ano atual - 3 anos
              this.obj1.data[3] = res.data[13].Quantidade
            }

            // 8 ~ 11
            if(i > 7 && i < 12){
              this.obj2.name = 'Anos Iniciais'
              // creche do ano atual - 2 anos
              this.obj2.data[0] = res.data[2].Quantidade
              // pre escola do ano atual - 2 anos
              this.obj2.data[1] = res.data[6].Quantidade
              // anos iniciais do ano atual - 2 anos
              this.obj2.data[2] = res.data[10].Quantidade
              // anos finais do ano atual - 2 anos
              this.obj2.data[3] = res.data[14].Quantidade
            }

            // 12 ~ 15
            if(i > 11 && i < 16){
              this.obj3.name = 'Anos Finais'
              // creche do ano atual - 1 anos
              this.obj3.data[0] = res.data[3].Quantidade
              // pre escola do ano atual - 1 anos
              this.obj3.data[1] = res.data[7].Quantidade
              // anos iniciais do ano atual - 1 anos
              this.obj3.data[2] = res.data[11].Quantidade
              // anos finais do ano atual - 1 anos
              this.obj3.data[3] = res.data[15].Quantidade
            }
          }
          this.addSeries()            
        })
      },
      addSeries(){
        this.series.push(this.obj)
        this.series.push(this.obj1)
        this.series.push(this.obj2)
        this.series.push(this.obj3)
      }
    },
    mounted() {
      this.getByYears()
    },
  }
</script>