<template>
    <div id="chart">
        <apexchart :height="$vuetify.breakpoint.lgAndUp ? '250' : '360'" type="radialBar" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    props: {
        idEtapa: Number,
        idAno: Number
    },
    data() {
        return { 
            series: [],
            chartOptions: {
                chart: {
                    height: 390,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 90,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: '60%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: true,
                            },
                            value: {
                                show: true,
                                formatter: function (val) {
                                    return val + '%'
                                }
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                color: '#373d3f',
                                fontSize: '16px',
                                fontFamily: undefined,
                                fontWeight: 600,
                                formatter: function (w) {
                                        return w.globals.seriesTotals.reduce((a, b) => {
                                            return ((a / 100) * JSON.parse(sessionStorage.getItem('totalUrbano'))) + ((b / 100) * JSON.parse(sessionStorage.getItem('totalUrbano')))
                                        })
                                    }
                                }

                        },
                    }
                },
                // colors: ['#1ab7ea', '#0084ff', '#39539E'],
                labels: ['Masculino', 'Feminino'],
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '16px',
                    position: 'top',
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        useSeriesColors: true,
                    },
                    markers: {
                        size: 0
                    },
                    formatter: function(seriesName, opts) {
                        return seriesName + ":  " + JSON.parse(sessionStorage.getItem('unionUrbano'))[opts.seriesIndex]
                    },
                    itemMargin: {
                        vertical: 3
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            show: false
                        }
                    }
                }]
            },
        }       
    },
    methods: {
        getAlunosUrbana() {
            this.axios.get('turma/alunossexo', {
                params: {
                    IdEtapa: this.$props.idEtapa,
                    ano: this.$props.idAno,
                    Zona: 2
                },
            }).then(res => {
                let series = []
                this.series.push(((res.data[1].Quantidade / (res.data[1].Quantidade + res.data[0].Quantidade)) * 100).toFixed(1))
                this.series.push(((res.data[0].Quantidade / (res.data[1].Quantidade + res.data[0].Quantidade)) * 100).toFixed(1))
                sessionStorage.setItem('totalUrbano', JSON.stringify(res.data[0].Quantidade + res.data[1].Quantidade))
                series.push(res.data[1].Quantidade)
                series.push(res.data[0].Quantidade)
                sessionStorage.setItem('unionUrbano', JSON.stringify(series))
                // this.chartOptions.labels.push(res.data[1].Sexo)
                // this.chartOptions.labels.push(res.data[0].Sexo)
            });
        },
    },
    mounted() {
        sessionStorage.removeItem('unionUrbano')
        this.getAlunosUrbana()
    }
}
</script>
