<template>
  <div id="chartPercentual">
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
export default {
  props: {
    idUnidade: Number,
    idSerie: Number,
    idAno: Number,
    idBimestre: String
  },
  data() {
    return {
      series: [ { name: 'Acima da média', data: [] }, { name: 'Abaixo da média', data: [] } ],
      chartOptions: {
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: []
        }
      },
    };
  },
  methods: {
    getData() {
      this.axios.get("turma/situacaoBimestre", {
        params: {
          ano: this.$props.idAno,
          idUnidade: this.$props.idUnidade,
          idserie: this.$props.idSerie,
          divisao: this.$props.idBimestre,
        },
      }).then(res => {
        this.chartOptions = {
          xaxis: {
            categories: []
          }
        }
        res.data.filter(arr => {
          //if(arr.IdDivisao == this.$props.idBimestre){
            this.series[0].data.push(arr.QtdAlunosAcima)
            this.series[1].data.push(arr.QtdAlunosBaixa)
            this.chartOptions.xaxis.categories.push(arr.Disciplina)
          //}
        })
      });
    }
  },
  mounted() {
    this.getData();
  },
};
</script>