<template>
    <v-container>
      <v-row class="justify-center pt-2">
        <v-card width="95%" class="primary rounded">
          <v-card-title class="primary" style="color: white">Minhas Turmas </v-card-title>
          <v-data-table
              :headers="headersTurmas"
              :items="turmasList"
              :loading="loading"
              loading-text="Carregando..."
              item-value="turma"
              class="elevation-1"
              style="width: 100%"
              show-expand
              :expanded.sync="expanded"
              single-expand
              item-key="IdTurma"
          >
          <template v-slot:[`item.TurmaDescricao`] = "{ item }">
            <span>{{ item.Serie+' - '+item.TurmaDescricao, }}</span>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td colspan="12" style="padding: 10px">
              <v-data-table
                :headers="headersDisciplinas"
                :items="item.disciplinas"
                class="elevation-0"
                show-expand
                :expanded.sync="expandedDisciplina"
                single-expand
                item-key="IdTurmaDisciplina"
              >
              <template v-slot:[`item.frequencia`] = "{ item }">
                <span>{{ calcularFrequencia(item.TotalPresencas, item.TotalAulas) }}%</span>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td colspan="12" style="padding: 10px">
                  <v-data-table
                    :headers="headersFrequencia"
                    :items="item.frequencia"
                    class="elevation-0"
                    single-expand
                  >
                  <template v-slot:[`item.DataAula`] = "{ item }">
                    {{`${$moment(item.DataAula).format('L')}`}}
                  </template>
    
                  <template v-slot:[`item.Presenca`] = "{ item }">
                    <v-chip color="green" dark v-if="item.Presenca == item.aulas"> Presente </v-chip>
                    <v-chip color="orange" dark v-else-if="item.Presenca != 0 && item.Presenca < item.aulas"> {{item.aulas - item.Presenca }} Falta (s) </v-chip>
                    <v-chip color="red" dark v-else> {{ item.aulas - item.Presenca }} Falta (s) </v-chip>
                  </template>
                  </v-data-table>
                </td>
              </template>
              </v-data-table>
            </td>
          </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
</template>

<script>
export default {
  name: "AlunoFrequencia",

  data: () => ({
    expanded: [],
    IdPessoaFisica: null,
    expandedDisciplina: [],
    turmasList:[],
    loading: false,
    headersTurmas: [
      {text: 'Ano Letivo', value: 'AnoLetivo', show: true, align: 'left', width: '11%'},
      {text: 'Escola', value: 'Unidade', show: true, width: '46%',align: 'left'},
      {text: 'Turma', value: 'TurmaDescricao', show: true, width: '25%', align: 'left'},
    ],
    headersDisciplinas: [
      {text: 'Disciplina', value: 'Disciplina', show:true, width: '36%'},
      {text: 'Docente', value: 'Docente', show:true, width: '46%'},
      {text: 'frequencia', value: 'frequencia', show: true, align: 'center', width: '18%'},
    ],
    headersFrequencia: [
      {text: 'Data da Aula', value: 'DataAula', show: true, align: 'center', width: '50%'},
      {text: 'Situação', value: 'Presenca', align:'center',show: true, width: '50%'},
    ],

  }),
  methods:{
    calcularFrequencia(totalPresencas, totalAulas) {
      if (!totalAulas || totalAulas === 0) return 0;
      return ((totalPresencas * 100) / totalAulas).toFixed(2);
    },
    getAllData(){
      this.loading= true
      this.axios.get('turma/getalldata', {
        
            params: {
            IdPessoaFisica: this.IdPessoaFisica
            }
          }).then((res) => {
            if (!res.data.turmas[0].IdTurma) {
              this.turmasList = [];
              this.loading = false;
              return;
            }
        this.turmasList = res.data.turmas
        this.loading= false
      })
    },
    getDados(){
      this.axios.get('usuario').then(res => {
        this.IdPessoaFisica = res.data.IdPessoaFisica
      })
    },
  },
  mounted(){
    this.getDados();
    this.getAllData();
  }
}
</script>