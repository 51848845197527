<template>
    <v-select
        :items="list"
        v-model="defaults"
        item-text="name"
        item-value="id"
        label="Filtrar pelo bimestre"
        @change="sendMessageToParent($event)"
        hide-details
        outlined
        dense
    ></v-select>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    id: '1',
                    name: '1° Bimestre'
                },
                {
                    id: '2',
                    name: '2° Bimestre'
                },
                {
                    id: '3',
                    name: '3° Bimestre'
                },
                {
                    id: '4',
                    name: '4° Bimestre'
                }
            ],
            defaults: '1'
        }
    },
    methods: {
        sendMessageToParent(value){
            this.$emit('messageFromChild', value)
        }
    }
}
</script>
