<template>
  <div>
    <v-toolbar
        dense
        color="primary"
        dark
        extended
        extension-height="300"
        flat
    >
      <v-toolbar-title class="mx-auto pb-5" slot="extension">
        <span class="font-for-mobile">Bem vindo ao Portal de Serviços</span>
        <h2 class="font-for-mobile" style="text-align: center">Você encontra tudo aqui.</h2>
        <v-layout justify-space-between class="mt-4">
          <v-flex wrap>
            <v-btn
                class="mx-2"
                fab
                dark
                large
                outlined
                href="http://sis.pedrasdefogo.dti.systems/sis"
                target="__blank"
            >
              <v-icon color="white" dark>
                mdi-account-details
              </v-icon>
            </v-btn>
            SIS
          </v-flex>
          <v-flex wrap>
            <v-btn
                class="mx-2"
                fab
                dark
                large
                outlined
                href="http://sis.pedrasdefogo.dti.systems/sis"
                target="__blank"
            >
              <v-icon color="white" dark>
                mdi-school
              </v-icon>
            </v-btn>
            GE
          </v-flex>
          <!-- <v-flex wrap>
            <v-btn
                class="mx-2"
                fab
                dark
                large
                outlined
                href="http://sis.macaiba.dti.systems/sis/acesso.php"
                target="__blank"
            >
              <v-icon color="white" dark>
                mdi-medical-bag
              </v-icon>
            </v-btn>
            GS
          </v-flex> -->
        </v-layout>
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout row class="pt-10" justify-center>
        <v-flex xs12 md12 lg10 xl8>
          <v-card flat class="card--flex-toolbar" color="transparent">
            <v-container fluid grid-list-lg>
              <v-layout row wrap>
                <v-flex xs12 :style="$vuetify.breakpoint.xsOnly ? 'text-align: center' : ''">
                  <h4 class="white--text" style="text-align: center">Explore nossos recursos</h4>
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-3 justify-space-between v-if="$vuetify.breakpoint.smAndUp">
                <v-flex xs11 sm6 md4 lg4 xl4>
                  <div class="card-zoom-box">
                    <v-card shaped class="rounded-card mx-auto mt-5 card-zoom"
                      max-width="270"
                      elevation="1"
                    >
                      <div class="image-zoom-box">
                        <v-img
                          class="image-zoom"
                          src="../assets/dashboard1.png"
                          aspect-ratio="2.75"
                          height="125px"
                        ></v-img>
                      </div>
                      <v-card-title>Dashboards</v-card-title>
                      <v-card-subtitle>Indicadores, gráficos e mapas que irão possibilitar a visualização de informações pertinentes para análises quantitativas e qualitativas em suas respectivas esferas.</v-card-subtitle>
                    </v-card>
                  </div>
                </v-flex>
                <v-flex xs11 sm6 md4 lg4 xl4>
                  <div class="card-zoom-box">
                    <v-card shaped class="rounded-card mx-auto mt-5 card-zoom"
                      max-width="270"
                      elevation="1"
                    >
                      <div class="image-zoom-box">
                        <v-img
                          class="image-zoom"
                          src="../assets/saude.jpg"
                          aspect-ratio="2.75"
                          height="125px"
                        ></v-img>
                      </div>
                      <v-card-title>Cidadão</v-card-title>
                      <v-card-subtitle>Possibilita acompanhar os acessos e utilizações dos serviços ofertados pela rede de saúde. Informações referente a exames, dispensações, cadastro e validação de requerimentos.</v-card-subtitle>
                    </v-card>
                  </div>
                </v-flex>
                <v-flex xs11 sm6 md4 lg4 xl4>
                  <div class="card-zoom-box">
                    <v-card shaped class="rounded-card mx-auto mt-5 card-zoom"
                      max-width="270"
                      elevation="1"
                    >
                      <div class="image-zoom-box">
                        <v-img
                          class="image-zoom"
                          src="../assets/educacional.jpg"
                          aspect-ratio="2.75"
                          height="125px"
                        ></v-img>
                      </div>
                      <v-card-title>Aluno(a)</v-card-title>
                      <v-card-subtitle>Possibilita, além dos dados relacionados ao acesso no sistema de saúde municipal, informações referentes a sua situação escolar, como frequência, boletim e notas.</v-card-subtitle>
                    </v-card>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout column mb-3 v-else>
                <v-flex xs12 sm6 md4 lg4 class="pa-0">
                    <v-card
                      flat
                      class="mt-2"
                      width="100%"
                    >
                        <v-img
                          width="100%"
                          src="../assets/dashboard1.png"
                          aspect-ratio="2.75"
                          height="160px"
                        ></v-img>
                      <v-card-title>Dashboards</v-card-title>
                      <v-card-subtitle>Vários indicadores, gráficos e mapas detalhados que possibilitarão diversas informações e análises quantitativas e qualitativas em suas respectivas esferas</v-card-subtitle>
                    </v-card>
                </v-flex>
                <v-flex xs11 sm6 md4 lg4 class="pa-0">
                    <v-card
                      flat
                      class="mt-2"
                      width="100%"
                    >
                        <v-img
                          width="100%"
                          src="../assets/saude.jpg"
                          aspect-ratio="2.75"
                          height="160px"
                        ></v-img>
                      <v-card-title>Saúde/Cidadão</v-card-title>
                      <v-card-subtitle>Cadastro e validação de requerimentos, exames e dispensações, bem como a visualização de seu próprio contra-cheque e painéis públicos com indicadores</v-card-subtitle>
                    </v-card>
                </v-flex>
                <v-flex xs11 sm6 md4 lg4 class="pa-0">
                    <v-card
                      flat
                      class="mt-2"
                      width="100%"
                    >
                        <v-img
                          width="100%"
                          src="../assets/educacional.jpg"
                          aspect-ratio="2.75"
                          height="160px"
                        ></v-img>
                      <v-card-title>Educacional</v-card-title>
                      <v-card-subtitle>Boletim, frequência, arquivos e aulas disponibilizadas pelos professores. Acesso dos diretores e professores ao andamento de suas turmas para mais informações</v-card-subtitle>
                    </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
  })
}
</script>

<style lang="scss">
.outine-2 {
  border: 2px solid white;
}
.card--flex-toolbar {
  margin-top: -124px;
}
.learn-more-btn {
  text-transform: initial;
  text-decoration: underline;
}
.rounded-card {
    border-radius: 24px;
}
.image-zoom {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    &:hover{
      cursor: pointer;
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1); /* IE 9 */
      transform: scale(1.1);
    }
    &-box{
      overflow: hidden;
    }
}
.card-zoom {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    &:hover{
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1); /* IE 9 */
      transform: scale(1.1);
    }
    &-box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 110%;
    }
}

.font-for-mobile {
  font-size: 1.2em
}

</style>
