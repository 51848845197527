<template>
  <div class="page">
    <progress-system :isLoad="progress"></progress-system>
    <template>
      <div cols="12" style="width: 100%" :class="$vuetify.breakpoint.xsOnly ? 'container-fluid pl-5 pt-6 pb-2' : 'container-fluid pl-12 pt-6 pb-2'">
        <span style="font-weight: bolder">Minha conta</span>
        <v-divider vertical class="mx-4" color="black" style="display: inline; opacity: 0.3"></v-divider>
        <v-icon color="primary" class="pb-1" dense>
          mdi-home-outline
        </v-icon>
        <v-icon class="pb-1 mx-2">
          mdi-chevron-right
        </v-icon>
        <span style="color:#1976D2;font-weight: bolder">Meus dados</span>
        <v-icon class="pb-1 mx-2">
          mdi-chevron-right
        </v-icon>
        <span style="color:#1976D2;font-weight: bolder">{{ textoTelaAtual }}</span>
      </div>
      <v-card style="float:left"
          :class="$vuetify.breakpoint.xsOnly ? 'pl-4 pt-2' : 'pa-12 pt-0 mt-2 col-md-4 col-sm-5'"
          flat
      >
          <v-navigation-drawer
              floating
              permanent
          >
            <v-list
                dense
                rounded
                v-if="isFirstSession == false"
            >
              <v-list-item
                  v-for="item in items"
                  :key="item.title"
                  @change="changeTela(item)"
                  :style="item.hover"
                  link
              >
                <v-list-item-icon v-if="verificaTela() == item.title">
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-icon v-else>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content v-if="verificaTela() == item.title">
                  <v-list-item-title class="white--text" color="white">{{ item.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content v-else>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list
            dense
            rounded
            v-if="isFirstSession == true"
        >
          <v-list-item
              v-for="item in itemSenha"
              :key="item.title"
              @change="changeTela(item)"
              :style="item.hover"
              link
          >
            <v-list-item-icon v-if="verificaTela() == item.title">
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-icon v-else>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-if="verificaTela() == item.title">
              <v-list-item-title class="white--text" color="white">{{ item.title }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-content v-else>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
          </v-navigation-drawer>
      </v-card>
      <v-card
              style="float:left"
              :class="$vuetify.breakpoint.xsOnly ? 'pa-2 pt-0 mt-0 col-12' : 'pa-2 pt-0 col-md-7 mt-4 col-sm-7'"
              :elevation="$vuetify.breakpoint.xsOnly ? '0' : '1'"
      >
        <v-form v-show="views[0].isTrue"
                ref="formEmail"
                lazy-validation
                v-model="validEmail"
                >
          <v-container>
            <v-row>
              <v-col cols="2">
                <v-avatar
                    class="profile"
                    color="grey"
                    size="64"
                    tile
                >
                  <v-img v-if="this.dados.IdPessoaFisica!=null" :src="`http://sis.macaiba.dti.systems/sis/cliente/fotos/${this.dados.IdPessoaFisica}.jpg`" @onerror="target.src='https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png'"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="10">
                <v-row class="mt-2 pl-2">
                  <v-btn cols="6"
                      style="text-transform: none;"
                      color="primary"
                      small
                      :loading="isSelecting"
                      elevation="2"
                      class="mr-4 ml-4"
                      @click="onPickFile"
                  >
                    {{ buttonText }}
                    <v-icon
                        right
                        dark
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </v-btn>
                  <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/jpeg, image/jpg"
                      id="uploader"

                      @change="onFileChanged"
                  />
                  <v-btn
                      style="text-transform: none"
                      elevation="2"
                      small
                      outlined
                      color="primary"
                      @click="refreshImage()"
                  >
                    <span v-if="!isTruncate">Atualizar</span>
                    <v-icon v-if="!isTruncate"
                        right
                        dense
                    >
                      mdi-sync
                    </v-icon>
                    <v-icon v-else
                        dense
                    >
                      mdi-sync
                    </v-icon>
                  </v-btn>
                </v-row>
                <v-row class="mt-2 pl-2">
                  <span class="text--secondary ml-4" style="font-size: 0.9em"> Fotos permitidas com extensão .jpg ou .jpeg </span>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-2 pb-0">
                <v-text-field
                    label="Nome completo"
                    disabled
                    v-model="dados.Nome"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                    label="Email"
                    :rules="[rules.required]"
                    v-model="dados.Email"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                    label="Código (Pessoa Física)"
                    disabled
                    v-model="dados.IdPessoaFisica"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="this.dados.ConfirmacaoPendente == 1">
              <v-alert
                  dense
                  class="ml-2 text--primary"
                  border="left"
                  type="warning"
              >
                Por favor, verifique o seu endereço de <strong>email</strong> para confirmar as informações registradas
              </v-alert>
            </v-row>
            <v-row class="pt-0 pb-2">
              <v-btn
                  color="primary"
                  small
                  class="mr-4 ml-2"
                  :disabled="!validEmail"
                  @click="[updateTelaEmail()]"
              >
                Salvar alterações
              </v-btn>
              <v-btn
                  small
                  outlined
                  color="primary"
                  @click="[resetValidationEmail(), getDados()]"
              >
                Limpar campos
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
        <v-form v-show="views[1].isTrue"
                ref="formSenha"
                lazy-validation
                v-model="validSenha">
          <v-container>
            <v-row>
              <v-col cols="6" class="pt-2 pb-0">
                <v-text-field
                    label="Senha atual"
                    :rules="[rules.required]"
                    v-model="senhaAntiga"
                    :append-icon="show1Password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1Password = !show1Password"
                    :type="show1Password ? 'text' : 'password'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                    label="Nova senha"
                    required
                    placeholder="Digite a sua nova senha"
                    :rules="[rules.required, rules.min, rules.number, rules.specialCharacter]"
                    v-model="novaSenha"
                    :append-icon="show2Password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2Password = !show2Password"
                    :type="show2Password ? 'text' : 'password'"
                ></v-text-field>
                <v-text-field
                    label="Confirme sua nova senha"
                    required
                    placeholder="Confirme a sua nova senha"
                    :rules="[rules.required, rules.min, rules.number, rules.specialCharacter, passwordConfirmationRule]"
                    v-model="confirmarNovaSenha"
                    :append-icon="show3Password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show3Password = !show3Password"
                    :type="show3Password ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 pb-2">
              <v-btn
                  color="primary"
                  small
                  class="mr-4 ml-2"
                  :disabled="!validSenha"
                  @click="updateTelaSenha()"
              >
                Salvar alterações
              </v-btn>
              <v-btn
                  small
                  outlined
                  color="primary"
                  @click="[resetValidationSenha(), getDados()]"
              >
                Limpar campos
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
        <v-form 
        
        v-show="views[2].isTrue"
                ref="formInfo"
                lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="6" class="pt-2 pb-0">
                <v-text-field
                    label="Nome completo"
                    disabled
                    v-model="dados.Nome"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-2 pb-0">
                <v-text-field
                    label="Email"
                    disabled
                    v-model="dados.Email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                    label="Nome da mãe"
                    disabled
                    v-model="dados.NomeMae"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                    label="Nome do pai"
                    disabled
                    v-model="dados.NomePai"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="CPF"
                    disabled
                    v-model="dados.CPF"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="Número do RG"
                    v-mask="'###.###.###'"
                    :rules="[rules.required]"
                    v-model="dados.RGNumero"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0 pb-0">
                <v-menu
                    v-model="menuDataNascimento"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dados.DataNascimento"
                        label="Data de Nascimento"
                        readonly
                        :rules="[rules.required]"
                        v-bind="attrs"
                        v-on="on"
                        type="date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dados.DataNascimento"
                      @input="menuDataNascimento = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="CEP"
                    :rules="[rules.required, rules.minCEP]"
                    v-mask="'#####-###'"
                    v-model="dados.CEP"
                    @input="getDadosCEP($event)"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="Cidade"
                    :rules="[rules.required]"
                    v-model="dados.Localidade"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="Bairro"
                    :rules="[rules.required]"
                    v-model="dados.Bairro"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                    label="Endereço"
                    :rules="[rules.required]"
                    v-model="dados.Logradouro"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="Complemento"
                    v-model="dados.Complemento"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="pt-0 pb-0">
                <v-text-field
                    label="Numero"
                    :rules="[rules.required]"
                    v-model="dados.Numero"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="Telefone 1"
                    :rules="[rules.required]"
                    v-mask="'(##) #####-####'"
                    v-model="dados.Telefone1"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="Telefone 2"
                    required
                    v-mask="'(##) #####-####'"
                    v-model="dados.Telefone2"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0 pb-0">
                <v-text-field
                    label="Telefone 3"
                    required
                    v-mask="'(##) #####-####'"
                    v-model="dados.Telefone3"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 pb-2">
              <v-btn
                  color="primary"
                  small
                  class="mr-4 ml-2"
                  :disabled="!validInfo"
                  @click="[updateTelaInfo()]"
              >
                Salvar alterações
              </v-btn>
              <v-btn
                  small
                  outlined
                  color="primary"
                  @click="[resetValidationInfo(), getDados()]"
              >
                Limpar campos
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </template>
    <system-snackbar :snackbar="snackbar"></system-snackbar>
  </div>
</template>
<script>
import SystemSnackbar from './../components/SystemSnackbar'
import ProgressSystem from '../components/ProgressSystem'
export default {
  components: {
    ProgressSystem,
    SystemSnackbar,
  },
  name: 'About',
  data: () => ({
    snackbar: {
      show : false,
      timeout: 6000,
      align: 'right'
    },
    isFirstSession: false,
    progress: false,
    menuDataNascimento: false,
    email: 'oi@email.com',
    validSenha: true,
    validEmail: true,
    validInfo: true,
    isSelecting: false,
    files: null,
    textoBotaoImagem: 'Anexar imagem',
    password: 'senha1234',
    nome: 'Alvaro da peixeira peixoto',
    textoTelaAtual: 'Configurações',
    views: [
      {
        isTrue: true,
      },
      {
        isTrue: false,
      },
      {
        isTrue: false,
      }
    ],
    rules: {
      required: value => !!value || 'Obrigatório',
      min: v => v.length >= 6 || 'Senha precisa ter pelo menos 6 caracteres',
      minCEP: v => v.length === 9 || 'CEP inválido',
      number: v => v.match(".*\\d.*") || 'Senha precisa ter pelo menos um número',
      specialCharacter: v => v.match("(?=.*?[#?!@$%^&*-])") || 'Necessário pelo menos um caractere especial',
    },
    show1Password: false,
    show2Password: false,
    show3Password: false,
    senhaAntiga: null,
    novaSenha: null,
    confirmarNovaSenha: null,
    items: [
      { title: 'Geral', icon: 'mdi-account' , hover: '', id: 0},
      { title: 'Alterar senha', icon: 'mdi-lock', hover: '', id: 1},
      { title: 'Mais informações', icon: 'mdi-information', hover: '', id: 2},
    ],
    itemSenha: [
      { title: 'Alterar senha', icon: 'mdi-lock', hover: '', id: 1},
    ],
    novoDados:{
      dadosEmail: {
        Nome: null,
        Email: null,
      },
      dadosSenha: {
        SenhaAtual: "",
        SenhaNova: null,
      }
    },
    dados: {
      Nome: null,
      selectedFile: null,
      NomePai: null,
      NomeMae: null,
      CPF: null,
      CEP: "",
      Siafi: "",
      DDD: "",
      IBGE: "",
      UF: "",
      Senha: null,
      SenhaAntiga: null,
      DataNascimento: null,
      RGNumero: null,
      Email: null,
      IdPessoaFisica: null,
      Localidade: null,
      Bairro: null,
      Logradouro: null,
      Complemento: null,
      Numero: null,
      Telefone1: null,
      Telefone2: null,
      Telefone3: null,
      ConfirmacaoPendente: null,
    },
    emailOld: null,
    cepOld: null,
    textoTruncate: null,
    isTruncate: false,
  }),
  computed: {
    passwordConfirmationRule() {
      return () => (this.confirmarNovaSenha === this.novaSenha) || 'Senhas não conferem'
    },
    buttonText() {
      return this.dados.selectedFile ? this.textoTruncate : this.textoBotaoImagem
    }
  },
  methods:{
    changeTela(item){
      this.textoTelaAtual = item.title
      for(var i=0;i<this.items.length;i++){
        this.items[i].hover = ''
        this.views[i].isTrue = false
      }
      item.hover = 'box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.24);transition: 0.2s all;background-color: #1976D2;text-rendering: optimizelegibility;-moz-osx-font-smoothing: grayscale;-moz-text-size-adjust: none;'
      this.views[item.id].isTrue = true
    },
    verificaTela(){
      return this.textoTelaAtual
    },
    //NOME E EMAIL
    validateEmail(){
      return this.$refs.formEmail.validate()
    },
    resetValidationEmail(){
      this.$refs.formEmail.resetValidation()
      this.$refs.uploader.value = null
      this.dados.selectedFile = null
      this.isTruncate = false
    },
    refreshImage(){
      location.href = "/About"
    },
    updateTelaEmail(){
      if(this.dados.Email != this.emailOld){
        this.axios.put(`usuario/${this.dados.IdPessoaFisica}/email/update`, this.dados).then(() => {
          this.getDados()
          this.dados.ConfirmacaoPendente = 1
        })
      }
      if(this.validateEmail()) {
        this.update('Email')
      }
      
    },
    //SENHA
    validateSenha(){
      try {
        return this.$refs.formSenha.validate()
      } catch (error) {
        this.snackbar = {
          ...this.snackbar,
          show: true,
          text: 'Erro ao validar a senha. Por favor, tente novamente.',
          color: 'red'
        };
        return false;
      }
    },
    resetValidationSenha(){
      this.novaSenha = null
      this.confirmarNovaSenha = null
      this.$refs.formSenha.resetValidation()
    },
    updateTelaSenha() {
      if (this.validateSenha()) {
        this.novoDados.dadosEmail.Nome = this.dados.Nome;
        this.novoDados.dadosEmail.Email = this.dados.Email;
        this.novoDados.dadosSenha.SenhaAtual = this.senhaAntiga;
        this.novoDados.dadosSenha.SenhaNova = this.confirmarNovaSenha;

        this.axios.put(`usuario/${this.dados.IdPessoaFisica}`, this.novoDados)
          .then(res => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: res.data,
              color: 'green'
            };
            this.dados.ConfirmacaoPendente = 1;
          })
          .catch(e => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: e.response.data,
              color: 'red',
            };
            e.preventDefault();
          })
          .finally(() => {
            this.getDados();
            sessionStorage.setItem('mudarSenha', 0);
            // location.reload()
          });
      } else {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: 'Senha inválida. Por favor, tente novamente.',
              color: 'red'
            };
          }
    },

    //INFORMACOES
    validateInfo(){
      return this.$refs.formInfo.validate()
    },
    resetValidationInfo(){
      this.$refs.formInfo.resetValidation()
    },
    updateTelaInfo(){
      if(this.validateInfo()){
        this.update('Info')
      }
    },
    //UPDATE
    update(tela){
      let formData = new FormData();
      formData.append('files', this.dados.selectedFile);
      for (let key in this.dados) {
        formData.append(`dados${tela}[${key}]`, this.dados[key]);
      }
      formData.append("_method", "PUT");
      this.axios.post(`usuario/${this.dados.IdPessoaFisica}/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(res => {
        this.snackbar = {
          ...this.snackbar,
          show: true,
          text: res.data,
          color: 'green'
        }
      }).catch(e => {
        this.snackbar = {
          ...this.snackbar,
          show: true,
          text: e.response.data,
          color: 'red',
        }
      }).finally(() => {
        this.getDados()
      })
    },
    //SELECIONAR IMAGEM
    onPickFile() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })
      this.$refs.uploader.click()
    },
    //ATRIBUIR IMAGEM
    onFileChanged() {
      this.dados.selectedFile = this.$refs.uploader.files[0];
      if(this.dados.selectedFile.name.length > 15){
        this.textoTruncate = this.dados.selectedFile.name.substring(0, 15) + "...";
        this.isTruncate = true
      }else{
        this.textoTruncate = this.dados.selectedFile.name
        this.isTruncate = false
      }
    },
    getDadosCEP(){
      if(this.dados.CEP.length > 8) {
        var xhr = require("vue-xhr")
        xhr.get(`https://viacep.com.br/ws/${this.dados.CEP.replace('-','')}/json/`)
            .then((res)=>{
              this.dados.CEP = res.data.cep
              this.dados.Logradouro = res.data.logradouro
              this.dados.Bairro = res.data.bairro
              this.dados.Localidade = res.data.localidade
              this.dados.DDD = res.data.ddd
              this.dados.UF = res.data.uf
              this.dados.IBGE = res.data.ibge
              this.dados.Siafi = res.data.siafi
              this.cepOld = res.data.cep
            })
        if(this.cepOld != this.dados.CEP){
          this.dados.Numero = null
        }
      }
    },
    getDados(){
      this.axios.get('usuario').then(res => {
        this.dados.Nome = res.data.Nome
        this.dados.CPF = res.data.CPF
        this.dados.RGNumero = res.data.RGNumero
        this.dados.IdPessoaFisica = res.data.IdPessoaFisica
        this.dados.Email = res.data.Email
        this.dados.CEP = res.data.CEP
        this.dados.DataNascimento = res.data.DataNascimento
        this.dados.NomePai = res.data.NomePai
        this.dados.NomeMae = res.data.NomeMae
        this.dados.Logradouro = res.data.Logradouro
        this.dados.Complemento = res.data.Complemento
        this.dados.Localidade = res.data.Cidade
        this.dados.Numero = res.data.Numero
        this.dados.Bairro = res.data.Bairro
        this.dados.Telefone1 = res.data.Telefone1
        this.dados.Telefone2 = res.data.Telefone2
        this.dados.Telefone3 = res.data.Telefone3
        this.emailOld = res.data.Email
        this.cepOld = res.data.CEP
        this.dados.ConfirmacaoPendente = res.data.ConfirmacaoPendente
      })
    },
    userFirstSession()
		{
			if (sessionStorage.getItem('mudarSenha') == 1){
				this.isFirstSession = true
        this.changeTela(this.items[1])
			}
      else{
        this.changeTela(this.items[0])
      }
		},
  },
  mounted() {
    this.userFirstSession()
    this.getDados()
  }
}
</script>