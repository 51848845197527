<template>
    <div class="container-fluid">
        <v-row>
                <v-col cols="6">

                    <audio ref="audio">
                        <source src="@/static/bell-song.mp3" type="audio/mp3">
                    </audio>
                    <v-card height='500PX'>
                            <v-layout column>

                                <h4 v-if="NomePaciente" class="primary--text text-center font-weight-bold" style="font-size:80px">
                                    {{ NomePaciente }}
                                </h4>
                                <h4 v-else class="primary--text text-center font-weight-bold mt-5" style="font-size:180px">
                                    {{ Senha }}
                                </h4>

                                <h4 class="primary--text text-center font-weight-bold" style="font-size:35px">
                                    {{ Local }}
                                </h4>
                                
                                <h4 v-if="Classificacao == 'VERDE'" class="green--text text-center font-weight-bold" style="font-size:35px">
                                    {{ Classificacao }}
                                </h4>
                                <h4 v-if="Classificacao == 'LARANJA'" class="orange--text text-center font-weight-bold" style="font-size:35px">
                                    {{ Classificacao }}
                                </h4>
                                <h4 v-if="Classificacao == 'AMARELO'" class="yellow--text text-center font-weight-bold" style="font-size:35px">
                                    {{ Classificacao }}
                                </h4>
                                <h4 v-if="Classificacao == 'VERMELHO'" class="red--text text-center font-weight-bold" style="font-size:35px">
                                    {{ Classificacao }}
                                </h4>
                                <h4 v-if="Classificacao == 'AZUL'" class="primary--text text-center font-weight-bold" style="font-size:35px">
                                    {{ Classificacao }}
                                </h4>
                                
                                <v-alert
                                shaped
                                dense
                                dark
                                color="info"
                                >
                                O tempo de espera para o resultado do(s) exame(s) é aproximadamente 2 horas;
                                </v-alert>
                            </v-layout>

                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card  height='500px' max-height="500px">
                        <!-- <video :key="reload" width="100%" height="98%" v-on:ended="nextVideo" preload="auto" muted="true" controls autoplay > -->
                        <!-- <video id="Video" :key="reload" width="100%" height="100%" v-on:ended="nextVideo" preload="auto" :muted=true autoplay controls>
                            <source :src="videoArray[index]" type="video/mp4">
                        </video> -->
                        <!-- <iframe id="Video" width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?list=PL12jx8elnGwbw8O-gUkqfvQuTc6UD3cvm&autoplay=1&mute=1&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen muted></iframe> -->
                        <!-- <iframe width="100%" height="100%" src="https://youtube.com/playlist?list=PL12jx8elnGwbw8O-gUkqfvQuTc6UD3cvm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                     -->
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <marquee class="red--text font-weight-bold" style="font-size: 1.5em"> TEMPO DE ESPERA PARA CADA TIPO DE CLASSIFICAÇÃO DE RISCO: VERMELHO - IMEDIATO/RISCO DE MORTE; LARANJA - 10 MINUTOS DE ESPERA; AMARELO - 60 MINUTOS DE ESPERA; VERDE - 120 MINUTOS DE ESPERA; AZUL - 240 MINUTOS DE ESPERA;   </marquee>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <v-card  height='430' style="overflow-y:auto">
                        <h4 class="primary--text font-weight-bold pt-5" style="text-align: center !important">
                            ÚLTIMAS SENHAS CHAMADAS
                        </h4>
                        <v-data-table
                        :headers="headersSenhasChamadas"
                        :items="senhasChamadasList"                    
                        itemsPerRow="6"
                        >
                            <template v-slot:[`item.Senha`]=" { item } ">
                                <span class="primary--text" style="font-size: 2rem"> {{item.Senha}} </span>
                            </template>

                            <template v-slot:[`item.Nome`]=" { item } ">
                                <span class="primary--text" style="font-size: 2.5rem"> {{item.Nome}} </span>
                            </template>

                            <template v-slot:[`item.strLocal`]=" { item } ">
                                <span class="primary--text" style="font-size: 1.5rem"> {{item.strLocal}} </span>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>

                <v-col cols="4">
                        <dashboard-card-plantonistas :items="plantonistasList" :show="true"></dashboard-card-plantonistas>
                </v-col>

            </v-row>
    </div>

</template>

<script>
 
import DashboardCardPlantonistas from '@/components/painel/DashboardCardPlantonistas.vue'
import { delay } from '@/utils/delay.js'

export default {
    components: {
		DashboardCardPlantonistas
	},
    watch: {
        init: {
            immediate: true,
            handler() {
                this.getUltimaFicha()
                this.getSenhasChamadas()
                this.getPlantonistas()
                this.getVideos()
            }
        }
    },
    data() {        
        return {
            definirMudo: null,
            senhasChamadasList:[],
            plantonistasList:[],
            Visualizado: null,
            NomePaciente: null,
            Local: null,
            Classificacao: null,
            Senha: null,
            idUnidade: 1,

            headersSenhasChamadas:[
                    {text:'Número da senha', value:'Senha', width:"20%", align:"center"},
                    {text:'Nome do paciente', value:'Nome', width:"60%", align:"left"},
                    {text:'Local', value:'strLocal', width:"20%", align:"center"}
                ],
            reload: null,
            index: 0,
            videoArray: [],
        }
    },
    methods: {
        deixarmudo(){
            this.definirMudo = true
        },
        desmutar(){
            this.definirMudo = false
        },
        falarNome(nome){
            let msg = new SpeechSynthesisUtterance()
            msg.text = nome
            window.speechSynthesis.speak(msg)
        },
        async getSenhasChamadas(){
            const { data } = await this.axios.get('painel/senhaschamadas', { params: { idUnidade: this.idUnidade } })
            this.senhasChamadasList = data
            setTimeout(this.getSenhasChamadas, 20000);
        },
        async getPlantonistas(){
            const { data } = await this.axios.get('escala', { params: { idUnidade: this.idUnidade } })
            this.plantonistasList = await data.map((escala, index) => {
                return {
                    id: index,
                    Nome: escala.Nome,
                    Tipo: escala.TipoProfissional,
                }
            })
            setTimeout(this.getPlantonistas, 30*60*20000);
        },

        async getUltimaFicha(){
			const { data } = await this.axios.get('painel/ultimasenhachamada', { params: { idUnidade: this.idUnidade } })
            data.forEach(element => {
                    this.Senha = element.Senha
                    this.NomePaciente=  element.Nome
                    this.Local= element.strLocal
                    this.Classificacao= element.Classificacao
                    this.Visualizado= element.Visualizado
            })
                
            if (this.Visualizado < 2) {
                this.$refs.audio.play()
                await delay(1000);
                this.NomePaciente
                    ? this.falarNome(this.NomePaciente)
                    : this.falarNome('Senha número:' + this.Senha)
                this.falarNome(this.Local)
            }
            setTimeout(this.getUltimaFicha, 20000);
		},
        nextVideo(){
            if(this.index >= this.videoArray.length - 1){
                this.index = 0
                this.reload++
            }
            else{
                this.index++
                this.reload++
            }
        },
        // getVideos(){
            
        //     this.axios.get('videos/index').then(res => {
        //         const array = res.data
        //         array.forEach(element => {
        //             this.videoArray.push("https://s3.us-west-2.amazonaws.com/portal.macaiba.dti.systems/videos/"+element.key)
        //             this.nextVideo()
        //         });
        //     })
        // },
        
    },
    mounted() {
        setInterval(() => {
            location.reload();
        }, 1800000); // timer de 30 minutos(para uma hora 3600000)
    },
}

</script>

<style>
    ::-webkit-scrollbar {
        display: none;
    }
</style>