<template>
    <div class="container-fluid">
        <v-card >
            <!-- <video id="Video" :key="reload" width="100%" v-on:ended="nextVideo" preload="auto" autoplay controls>
                <source :src="videoArray[index]" type="video/mp4">
            </video> -->
            <!-- <iframe id="Video" width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?list=PL12jx8elnGwbw8O-gUkqfvQuTc6UD3cvm&autoplay=1&mute=1&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen muted></iframe> -->
        </v-card>
    </div>
</template>
<script>
export default {
    watch: {
        init: {
            immediate: true,
            handler() {
                this.getVideos()
            }
        }
    },
    data() {
        return {
            index: 0,
            recarregar:null,
            videoArray: [],
        }
    },
    methods: {
        nextVideo(){
            if(this.index >= this.videoArray.length - 1){
                this.index = 0
                this.reload++
            }
            else{
                this.index++
                this.reload++
            }
        },
        async getVideos(){
            const { data } = await this.axios.get('videos/index')  
            data.forEach(element => {
                this.videoArray.push("https://s3.us-west-2.amazonaws.com/portal.macaiba.dti.systems/videos/"+element.key)
                this.nextVideo()
            })
        }
    },
    mounted() {
        setInterval(() => {
            location.reload();
        }, 1800000); // timer de 30 minutos(para uma hora 3600000)
    },
}
</script>
<style>
.video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}
</style>